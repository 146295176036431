"use client"

import Footer from "@/containers/home-page/Footer"
import BaseHeader from "@/containers/home-page/header/BaseHeader"
import { ROUTES } from "@/interfaces/constants/routes"
import { ChevronLeftOutlined, HomeOutlined } from "@mui/icons-material"
import { Box, Button, Container, Typography, useTheme } from "@mui/material"
import Head from "next/head"
import { useRouter } from "next/navigation"

export default function Custom404() {
  const router = useRouter()
  const theme = useTheme()

  const handleBack = () => {
    router.back()
  }

  const handleGoHome = () => {
    router.push(ROUTES.HOME)
  }

  return (
    <>
      <Head>
        <title>404 - Página não encontrada | MS.gov.br</title>
        <meta
          name="description"
          content="A página que você procura não existe ou não está disponível. Retorne à página inicial ou use os links fornecidos para continuar a navegação no site do MS Qualifica."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <BaseHeader isHome={false} />
        <Container
          sx={{
            mt: `${theme.appHeader.height}px`,
            flex: "1 0 auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", sm: "720px" },
              background: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "16px",
              padding: { xs: 2, sm: 4 },
              textAlign: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: 48, sm: 64 }, color: "#313131", fontWeight: 500 }}
            >
              404
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: 24, sm: 32 },
                color: "#313131",
                fontWeight: 500,
                marginBottom: 2,
              }}
            >
              Página não encontrada.
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: 14, color: "#212427", marginBottom: 4 }}
            >
              A página que você procura não existe ou não está disponível. Retorne à
              página inicial ou verifique o endereço informado.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "blueMsGov.main",
                  borderColor: "blueMsGov.main",
                  padding: "8px 16px",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.75)",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleBack}
                startIcon={<ChevronLeftOutlined />}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Voltar</Typography>
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "blueMsGov.main",
                  borderColor: "blueMsGov.main",
                  padding: "8px 16px",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.75)",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleGoHome}
                startIcon={<HomeOutlined />}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  Página Inicial
                </Typography>
              </Button>
            </Box>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  )
}
